<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h2>
          Privacy statement
        </h2>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="mb-4 text-left">
        <p class="font-weight-regular">
          Humanwise is ervan overtuigd dat bescherming van jouw resultaten uit de analyses en jouw persoonlijke gegevens van groot belang is. Zowel
          voor jou als voor het vertrouwen dat wij van jou krijgen. Met jouw resultaten en persoonlijke data wordt dan ook met de grootste zorg en
          beveiliging omgegaan.
        </p>

        <p class="font-weight-regular">
        Wanneer je een analyse van Humanwise hebt gemaakt en jouw persoonlijke gegevens hebt ingevuld, wordt dit door Ivesa, onze IT partner,
        opgeslagen in een beveiligde omgeving. Deze informatie wordt door Humanwise gebruikt om (1) jouw persoonlijke analyse profiel samen te stellen, (2) de cultuur in jouw organisatie
        en/of team te analyseren en (3) statistisch onderzoek te doen voor eigen gebruik van Humanwise.
        </p>

        <p class="font-weight-regular">
        Jouw resultaten en persoonlijke data worden alleen voor deze bovengenoemde doelen gebruikt door Humanwise.
        </p>

        <p class="font-weight-regular">
        In een totaal overzicht van team/ organisatie scan zijn alleen de hoofduitkomsten zichtbaar en geen detailinformatie van jouw profiel.
        </p>

        <p class="font-weight-regular">
        Natuurlijk ben je zelf vrij in het delen van jouw persoonlijke resultaten met collega’s of leidinggevende.
        </p>

        <p class="font-weight-regular">
        Mocht je vragen hebben, neem dan gerust contact met ons op.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Privacy",


};
</script>
